<template>
  <div class="studyLiving clearfix">
    <div class="studyLivingfl fl" v-if="Object.keys(Calender).length">
      <div class="studyLivingfldiv">
        <h5 class="studyLivingfldivh5">{{day}}</h5>
        <p class="studyLivingfldivp">{{Calender[day-1].year}}年{{Calender[day-1].month}}月{{Calender[day-1].day}}日 {{Calender[day-1].week}}</p>
        <div class="studyLivingfldivd clearfix">
          <p class="fl">{{Calender[day+1].nongli}}</p>
          <span class="fr" @click="backnow"
            ><i class="iconfont icon-study_tody"></i>回到今天</span
          >
        </div>
      </div>
      <div class="studyLivingfldiv1">
        <i class="iconfont icon-lesson_file_last" @click="monpre(Calender[day+1].year,Calender[day+1].month)"></i>
        <span>{{Calender[day+1].year}}年{{Calender[day+1].month}}月</span>
        <i class="iconfont icon-lesson_file_next" @click="monext(Calender[day+1].year,Calender[day+1].month)"></i>
      </div>
      <div class="studyLivingfldiv2">
        <ul class="studyLivingfldiv2oul1 clearfix">
          <li>一</li>
          <li>二</li>
          <li>三</li>
          <li>四</li>
          <li>五</li>
          <li>六</li>
          <li>日</li>
        </ul>
        <ul class="studyLivingfldiv2oul2 clearfix">
          <li v-for="inde in week" :key="'o'+inde"></li>
          <li v-for="(item,index) in Calender" :key="index" :class="{'on':index==ons,'on2':item.gx}" @click="change(item.day,index)" >
            <span>{{(item.year==year&&parseInt(item.month)==month&&item.day==day)?'今':item.day}}</span>
            <i>&nbsp;</i>
          </li>
        </ul>
      </div>
    </div>
    <div class="studyLivingfr fr">
      <div class="studyLivingfrysj" v-if="UserLessonData.length!=0">
        <h5 class="studyLivingfrh5">当天共{{UserLessonData.length}}节直播</h5>
        <ul class="studyLivingfroul" v-for="(item,index) in UserLessonData" :key="index">
          <li class="clearfix">
            <div class="studyLivingfroulfl">
              <img :src="require('../assets/images/'+item.zb_status+'.png')" alt=""/>
            </div>
            <div class="studyLivingfroulfr">
              <div class="studyLivingfroulfrdiv1 clearfix">
                <p>{{item.start_time | formatimes('hh:mm')}} - {{item.end_time | formatimes('hh:mm')}}</p>
                <span>{{item.zb_status==0?'未开始':item.zb_status==1?'即将开始':item.zb_status==2?'直播中':'已结束'}}</span>
              </div>
              <h5 class="studyLivingfroulfrh5">{{item.cname}}</h5>
              <h6 class="studyLivingfroulfrh6">{{item.ltitle}}</h6>
            </div>
          </li>
        </ul>
      </div>
      <div class="studyLivingfrwsj"  v-else>
        <img src="../assets/images/study_zw.png" alt=""/>
        <p>暂无直播课程，看看其他时间吧</p>
      </div>
    </div>
  </div>
</template>

<script>
  import {liveCalendar} from '../assets/api.js'
export default {
  name: "studyLiving",
  data() {
    return {
      Calender:[],
      year:'',
      month:'',
      day:'',
      week:'',
      liveList:[],
      UserLessonData:[],
      ons:null,
      arrs:[]
    };
  },
  mounted() {
    this.year= new Date().getFullYear();
    this.month= new Date().getMonth()+1;
    this.day =  new Date().getDate();
    this.getCalendar(this.year,this.month)
  },
  methods: {
    backnow(){
      this.getCalendar(this.year,this.month)
    },
    monext(year,month){
      if(month==12){
        this.getCalendar(parseInt(year)+1,1)
      }else{
        this.getCalendar(year,parseInt(month)+1)
      }
    },
    monpre(year,month){
      if(month==1){
        this.getCalendar(parseInt(year)-1,12)
      }else{
        this.getCalendar(year,parseInt(month)-1)
      }
    },
    change(id,index){
      this.ons = index
      this.UserLessonData = []
      this.liveList.map(item=>{
        if(item.day==id){
          this.UserLessonData.push(item)
        }
      })
    },
    getCalendar(y,m){
      let params = {
        year:y,
        month:m
      }
      liveCalendar(params).then(res=>{
        if(res.code==0){
          this.Calender = res.data.Calender
          let n =  res.data.Calender[0].week
          switch(n){
            case "星期一":this.week=0;
            break;
            case "星期二":this.week=1;
              break;
            case "星期三":this.week=2;
              break;
            case "星期四":this.week=3;
              break;
            case "星期五":this.week=4;
              break;
            case "星期六":this.week=5;
              break;
            case "星期日":this.week=6;
              break;
          }
          this.liveList = res.data.UserLessonData
          let arr = []
          res.data.UserLessonData.map(item=>{
            if(item.day==this.day){
              this.UserLessonData.push(item)
            }
            arr.push(item.day)
          })
         this.arrs = [...new Set(arr)]
          this.Calender.map(item=>{
            this.arrs.map(it=>{
              if(item.day==it){
                this.$set(item,'gx',true)
              }
            })
          })
        }
      })
    }
  },
};
</script>

<style scoped lang="scss">
@import "../assets/css/studyLiving";
</style>
